<template>
  <div class="container">
    <!-- Left Sidebar Menu -->
    <div class="sidebar">
      <ul class="menu">
        <li
          @click="selectMenu('emraldServerManagement')"
          :class="{ active: selectedMenu === 'emraldServerManagement' }"
        >
          Emrald Server Management
        </li>
        <li
          @click="selectMenu('radxServerManagement')"
          :class="{ active: selectedMenu === 'radxServerManagement' }"
        >
          Radx Server Management
        </li>
        <li
          @click="selectMenu('prodDeployment')"
          :class="{ active: selectedMenu === 'prodDeployment' }"
        >
          Prod Deployment
        </li>
      </ul>
    </div>

    <!-- Main Content Area -->
    <div class="main-content">
      <!-- Emrald Server Management -->
      <div v-show="selectedMenu === 'emraldServerManagement'">
        <div>
          <h3>Emrald Server Management</h3>
          <p><b>Select Server Name To Control</b></p>
          <select
            class="form-control custom-select debug"
            v-model="serverName"
            @change="handleServerChange('emrald')"
          >
            <option value="">Select Server</option>
            <option value="Stage">Stage</option>
            <option value="Prod">Prod</option>
          </select>
        </div>

        <div v-if="serverName" class="server-setup">
          <div>
            <h6>{{ serverName }} Setup</h6>
            <hr />
          </div>
          <div class="button-group">
            <button
              v-if="isShowStartButton"
              class="button button1"
              @click="startServer('emrald')"
            >
              Start
            </button>
            <button
              v-if="isShowStopButton"
              class="button button2"
              @click="stopServer('emrald')"
            >
              Stop
            </button>
          </div>
        </div>

        <p v-else class="info-message">Please select a server to proceed.</p>
      </div>

      <!-- Radx Server Management -->
      <div v-show="selectedMenu === 'radxServerManagement'">
        <h3>Radx Server Management</h3>
        <div>
          <p><b>Select Server Name To Control</b></p>
          <select
            class="form-control custom-select debug"
            v-model="serverName"
            @change="handleServerChange('radx')"
          >
            <option value="">Select Server</option>
            <option value="Stage">Stage</option>
            <option value="Prod">Prod</option>
          </select>
        </div>

        <div v-if="serverName" class="server-setup">
          <div>
            <h6>{{ serverName }} Setup</h6>
            <hr />
          </div>
          <div class="button-group">
            <button
              v-if="isShowStartButton"
              class="button button1"
              @click="startServer('radx')"
            >
              Start
            </button>
            <button
              v-if="isShowStopButton"
              class="button button2"
              @click="stopServer('radx')"
            >
              Stop
            </button>
          </div>
        </div>
        <p v-else class="info-message">Please select a server to proceed.</p>
      </div>

      <!-- Prod Deployment -->
      <div v-show="selectedMenu === 'prodDeployment'">
        <h3>Prod Deployment</h3>
        <p>Deploy production updates here.</p>
      </div>
    </div>
  </div>
</template>

<script>
import AWS from "aws-sdk";
import { Signer } from "@aws-amplify/core";
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster();

export default {
  name: "ServerManagement",
  data() {
    return {
      selectedMenu: "emraldServerManagement", // Default menu selected
      serverName: "", // The selected server name
      isShowStartButton: false,
      isShowStopButton: false,
      credentials: null,
    };
  },
  mounted() {
    const uri = window.location.href.split("#");
    if (uri.length === 2) {
      const vars = uri[1].split("&");
      const idToken = vars[0].split("=")[1];
      if (idToken) this.getIdentityToken(idToken);
    }
  },
  methods: {
    selectMenu(menuName) {
      console.log(`Menu selected: ${menuName}`);
      this.selectedMenu = menuName; // Set the selected menu
    },
    async handleServerChange(serverType) {
      console.log(`Server selected: ${this.serverName}`);
      if (!this.serverName) return;

      const baseUrls = {
        emrald: {
          Stage: "https://emrald-qa.emrx.in/api/v1/releaseinfo",
          Prod: "https://emrald-prod.emrx.in/api/v1/releaseinfo",
        },
        radx: {
          Stage: "https://api-stage-radx.emrx.in/api/v1/releaseinfo",
          Prod: "https://api-radx.emrx.in/api/v1/releaseinfo",
        },
      };

      const url = baseUrls[serverType][this.serverName];

      try {
        await axios.get(url);
        this.isShowStartButton = false;
        this.isShowStopButton = true;
      } catch (error) {
        this.isShowStartButton = true;
        this.isShowStopButton = false;
      }
    },
    async serverControlChange(url, toastMessage) {
      const request = {
        method: "GET",
        url: url,
        data: "",
      };

      const accessInfo = {
        region: "ap-south-1",
        access_key: this.credentials?.AccessKeyId,
        secret_key: this.credentials?.SecretKey,
        session_token: this.credentials?.SessionToken,
      };

      const serviceInfo = { service: "execute-api", region: "ap-south-1" };
      const signedRequest = Signer.sign(request, accessInfo, serviceInfo);
      try {
        await axios(signedRequest);
        toaster.success(`Successfully ${toastMessage} service.`);
      } catch (error) {
        toaster.error(`Error occurred while ${toastMessage} service.`);
      }
    },
    async startServer(serverType) {
      const baseUrls = {
        emrald: `https://control.emrx.in/start?clusterName=emrald-${this.serverName.toLowerCase()}`,
        radx: `https://control.emrx.in/radx/start?clusterName=radx-${this.serverName.toLowerCase()}`,
      };

      const url = baseUrls[serverType];
      this.serverControlChange(url, "starting");
    },
    async stopServer(serverType) {
      const baseUrls = {
        emrald: `https://control.emrx.in/stop?clusterName=emrald-${this.serverName.toLowerCase()}`,
        radx: `https://control.emrx.in/radx/stop?clusterName=radx-${this.serverName.toLowerCase()}`,
      };

      const url = baseUrls[serverType];
      this.serverControlChange(url, "stopping");
    },
    getIdentityToken(idToken) {
      AWS.config.region = "ap-south-1";
      const cognitoidentity = new AWS.CognitoIdentity();
      const params = {
        IdentityPoolId: "ap-south-1:a6305d57-84ef-4507-9d8c-d3a6c308f941",
        AccountId: "260011671057",
        Logins: {
          "cognito-idp.ap-south-1.amazonaws.com/ap-south-1_1aCvlcUH4": idToken,
        },
      };

      cognitoidentity.getId(params, (err, data) => {
        if (err) return console.error(err);

        const credentialParams = {
          IdentityId: data.IdentityId,
          Logins: {
            "cognito-idp.ap-south-1.amazonaws.com/ap-south-1_1aCvlcUH4": idToken,
          },
        };

        cognitoidentity.getCredentialsForIdentity(credentialParams, (credErr, identityData) => {
          if (credErr) return console.error(credErr);
          this.credentials = identityData.Credentials;
        });
      });
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  height: 100vh;
  font-family: Arial, sans-serif;
}

.sidebar {
  width: 250px;
  background-color: #2c3e50;
  color: white;
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.sidebar .menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar .menu li {
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: background-color 0.3s, color 0.3s;
}

.sidebar .menu li:hover {
  background-color: #34495e;
}

.sidebar .menu li.active {
  background-color: #1abc9c;
  color: white;
}

.main-content {
  flex: 1;
  padding: 20px;
  background-color: #ecf0f1;
  overflow-y: auto;
}

h3 {
  color: #34495e;
}

.info-message {
  color: #e74c3c;
  font-weight: bold;
}

.button-group {
  display: flex;
  gap: 10px;
}

.button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.button1 {
  background-color: #27ae60;
  color: white;
}

.button1:hover {
  background-color: #2ecc71;
}

.button2 {
  background-color: #e74c3c;
  color: white;
}

.button2:hover {
  background-color: #c0392b;
}


</style>